// Variables
// =========
$stepstoneBlue:		#007899 
$stepstoneGrey:		#CBD6E2
$white:				#fff

$off-state: 	$stepstoneGrey
$on-state: 		$stepstoneBlue

// Mixins
// ======
@mixin flex($dir: row, $wrap: nowrap, $justify: center, $align: center)
	display: flex
	flex-flow: $dir $wrap
	justify-content: $justify
	align-items: $align

.switch
	position: relative
	width: 3rem
	height: 2rem
	transition: background-color 200ms ease-out
	z-index: 1
	display: inline-block
	&:before, &:after
		content: ""
		position: absolute
		top: 0
		background-color: inherit
		border-radius: 50%
		width: 2rem
		height: 2rem
		z-index: 2
	&:before
		left: -1rem
	&:after
		right: -1rem
	
.toggle-button
	position: absolute
	width: 2rem
	height: 2rem
	border-radius: 50%
	transition: transform 200ms ease-in-out
	z-index: 3
	top: -0.05rem

// State
.switch_is-off
	background-color: $off-state

.switch_is-on
	background-color: $on-state

.toggle-button_position-left
	transform: translateX(-1rem)
	background-color: $stepstoneBlue
	border: 0.05rem solid $stepstoneGrey

.toggle-button_position-right
	transform: translateX(2rem)
	background-color: $white
	border: 0.05rem solid $stepstoneBlue