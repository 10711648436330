$stepstoneBlue:		#007899
$stepstoneGrey:     #CBD6E2
$white:				#fff

$off-state: 	$stepstoneGrey
$on-state: 		$stepstoneBlue

.switchButton
    height: 40px
    width: 80px
    border: 1px solid $stepstoneGrey
    background: $white

.switch-left
    border-radius: 5px 0 0 5px

.switch-right
    border-radius: 0 5px 5px 0

.switch-on
    background: #cce3eb
    color: $stepstoneBlue