@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Roboto:wght@100;300;400&display=swap');

html, html body, body {
	font-weight: normal;
	font-size: 12px;
	margin: 0;
	line-height: normal;
    letter-spacing: normal;
    background-color: #fafafa
}

body {
    background-size: cover;
    background-size: cover;
    background-color: #FAFAFA;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

a {
    color: #007899;
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

input {
    min-height: 26px;
}

input.MuiInputBase-input {
    min-height: auto;
}

.swal2-container {
    z-index: 1500 !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}

.panel {
    background-color: #fff;
    padding: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.container-fluid {
    margin-top: 10px;
    margin-bottom: 10px;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    border-radius: 5px;   
    background: #007899;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%; 
    background: #BDBFBF;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #BDBFBF;
    cursor: pointer;
}

.docUploadModal {
    min-width: 300px;
    min-height:200px;
}

.dropzone {
    width: 300px;
    height: 200px;
    border-width: 2px;
    border-color: #666;
    border-style: dashed;
    border-radius: 5px;
}

.table-buttons {
    padding: 1px;
    color: #fff;
    border-color: #6c757d;
    background-color: #6c757d;
    margin: 6px 1px 1px 1px;
}

.link-button, .link-button:hover {
    text-decoration: none;
    color: #fff
}

.exposureRow {
    height: 35px;
}

.rc-slider-track-1 {
    pointer-events: none !important;
}

.rc-slider-track-3 {
    pointer-events: none !important;
}

.rc-slider-handle-1 {
    left: 0 !important;
    pointer-events: none !important;
}

.rc-slider-handle-4 {
    left: 100% !important;
    pointer-events: none !important;
}

.year-inputs {
    width: 100%;
}

.panel-tabs:hover, .panel-tabs-2:hover {
    text-decoration: none;
    color: #F7AD21;
}

.panel-tabs, .panel-tabs-2 {
    padding: 10px;
    color: white;
    font-size: 12px;
    font-weight: normal;
}

.panel-tabs-2 {
    color: black;
}

.panel-tabs:not(.panel-tab-active) {
    opacity: 0.25;
    color: black;
}

.panel-tab-active {
    border-top: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
    padding: 10px;
}

.panel-tab-active-2 {
    padding: 0;
    border-bottom: 1px solid #72ACC1;
    color: #72ACC1;
    padding-bottom: 19px;
    width: fit-content;
    margin: auto 10px;
}

.nav-tabs .nav-link {
    border: none;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 2px;
}

.nav-link {
    font-size: 16px;
    font-weight: bold;
}

.nav {
    align-items: center;
    padding-bottom: 10px;
}

.strategy-dropdown {
    height: 26px;
}

a.nav-link {
    font-family: 'Open Sans', sans-serif !important;
    color: rgb(66, 76, 84) !important;
    font-weight: 600 !important;
}

a.nav-link:hover {
    color: #007899 !important;
}

.nav-link-btn {
    font-family: 'Open Sans', sans-serif !important;
    color: rgb(66, 76, 84) !important;
    font-weight: 600 !important;
    background-color: transparent;
    border: none;
    font-size: 16px;
}

.nav-link-btn:hover {
    color: #007899 !important;
    background-color: transparent;
    border: none;
}

.nav-link-btn:active {
    color: #007899 !important;
    background-color: transparent !important;
    border: none !important;
}

.btn-menu-dropdown {
    width: 24px;
    height: 24px;
    color: rgb(102, 120, 138);
}

.menu-popover {
    background-color: #fcfcfc;
    z-index: 1300;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 20%;
    min-height: 100vh;
    overflow: auto;
    transition: 12.5s;
    box-shadow: 6px 7px 15px 5px rgba(208, 218, 228, 0.45) !important;
    border-right: 0.09em solid #eff1ef;
}

.menu-popover-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 1.2rem 0;
}

.menu-popover-title {
    font-size: 1.8rem;
    margin-bottom: 0.35em;
    line-height: 1.5;
    padding-left: 1.7rem;
}

.btn-no-style {
    border: none;
    background: transparent;
    padding-left: 0;
}

.btn-no-outline:focus, .btn-no-outline:active{
    outline: none;
    box-shadow: none;
}

.a-no-style {
    color: rgba(0, 0, 0, 0.8);
}

.app-list-icon {
    width: 30px !important;
    height: 30px !important;
}

.prf-drop-avatar {
    background-color: #66788a !important;
}

.prf-drop-avatar-small {
    width: 20px !important; height: 20px !important; font-size: 0.8rem !important;
}

.prf-drop-avatar-large {
    width: 30px !important; height: 30px !important; font-size: 1rem !important;
}

.prf-drop-menu {
    grid-template-columns: auto auto auto;
    flex-wrap: wrap;
    padding-top: 1em;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.nav-drop-btn {
    border-radius: 0px !important;
    width: 100%;
    height: 100%;
    min-width: 0px !important;
    box-sizing: border-box;
}

.nav-drop-btn:focus {
    outline: none;
}

.nav-drop-btn:hover {
    background-color: rgb(252, 252, 252, 0) !important;
}

.nav-drop-btn-label {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 600 !important;
    font-style: normal !important;
    font-size: 16px !important;
    text-transform: none !important;
}

.nav-drop-paper {
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
    border: 1px solid rgba(218, 221, 226, 1);
    box-shadow: 0px 4px 8px 0px rgba(69, 85, 96, 0.53) !important;
}

.keyInputs {
    text-align: right;
}

.active.nav-item a.nav-link {
    color: inherit;
    opacity: 0.5;
}

.nav-item-last {
    margin-left: auto;
    margin-right: 14px;
}

ol.breadcrumb {
    background-color: white;
}

@media(min-width: 1200px) {
    .target-allocation-type.ta-type-title {
        display: block !important;
    }
    .input-group-append.ta-type-addon {
        display: none !important;
    }
}

@media(min-width: 1550px) {
    .target-allocation-type.ta-type-title {
        display: none !important;
    }
    .input-group-append.ta-type-addon {
        display: block !important;
    }
}

.maringSide {
    margin-right: 7px;
}

.landing-body .panel {
    min-height: 90px;
}

.legend div {
    margin-left: 5px;
    margin-top: 5px;
}

.legend div::before {
    content: '';
    height: 10px;
    width: 10px;
    border-radius: 5px;
    display: inline-block;
    margin-right: 5px;
}

.legend .positive-unfunded-old::before {
    background-color: #007899;
}

.legend .underperforming-fund::before {
    background-color: #D9001B;
}

.legend .negative-values::before {
    background-color: #78A300;
}

.legend .missing-values::before {
    background-color: #F7AD21;
}

.legend .stale-nav-reporting::before {
    background-color: #F7E308;
}

.target-allocation-topper {
    min-height: 475px;
}

.underwriting-topper {
    min-height: 400px;
}

.rc-slider-tooltip-inner {
    margin-top: 15px;
}

.rc-slider-hover {
    position: absolute;
    bottom: 12px;
    font-size: 90% !important;
    padding-bottom: 2px !important;
}

.form-check-inline .form-check-label input.form-check-input {
    position: relative;
    top: 9px;
}

button.menu-button.btn.btn-secondary, 
button.menu-button.btn.btn-secondary:focus, 
button.menu-button.btn.btn-secondary:active
button.menu-button.btn.btn-secondary:active:focus {
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: #007899;
    font-size: 16px;
    font-weight: bold;
    margin-top: -1px;
}

button.menu-button.btn.btn-secondary:hover {
    color: #F7AD21;
}

button.menu-button-active.btn.btn-secondary,
button.menu-button-active.btn.btn-secondary:focus,
button.menu-button-active.btn.btn-secondary:active,
button.menu-button-active.btn.btn-secondary:active:focus {
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: rgba(33, 37, 41, 0.5);;
    font-size: 16px;
    font-weight: bold;
    margin-top: -1px;
}

.primary-button {
	min-width: 120px;
	background-color: #007899;
	color: #fff;
	border: 1px solid #007899;
	border-radius: 5px;
	height: 30px;
	margin: 5px;
}

.primary-button:hover {
	background-color: #248BA7;
}

.secondary-button {
	background-color: #fff;
	border: 1px solid #007899;
	color: #007899;
	min-width: 120px;
	border-radius: 5px;
	height: 30px;
	margin: 5px;
}

.secondary-button:hover {
    background-color: #F3F9FB;
    color: #007899;    
	border: 1px solid #007899;    
}

.disabled-button {
    background-color: #E7E9EF;
    color: #BDBFBF;
    min-width: 120px;
	border: 1px solid #E7E9EF;
	border-radius: 5px;
	height: 30px;
	margin: 5px;
}

/* Omni Import - Semantic multiselect Portfolio dropdown CSS override */
#portfolios, #Client, #currency {
    border: 1px solid #ced4da;
    height: 100%;
}

#portfolios > div, #Client > div, #currency > div {
    color: #495057;
    font-size: 1rem;
}

.ui.multiple.search.dropdown>input.search {
    margin: 2px 2px 2px 7px;
}

.togglePlacement {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-left: 2px;
    margin-bottom: 10px;
}

.portAumCalcLabel {
    position: relative;
    top: -16px;
}

@media(max-width: 1668px) {
    .portAumCalcLabel {
        position: relative;
        top: -23px;
    }
}

.m-top {
    margin-top: 20px;
}

.MuiTextField-root label.MuiFormLabel-root.Mui-focused {
    color: #007899;
}

.db-load label.Mui-focused,
.db-load .Mui-focused,
.db-load .MuiOutlinedInput-root fieldset,
.db-load .MuiOutlinedInput-root:hover fieldset,
.db-load .MuiOutlinedInput-root .Mui-focused fieldset {
    border-color: #007899 !important;
}

.end-year-input input.MuiInputBase-input {
    background: #FFF;
    min-height: 21px;
}

.ag-cell.ag-cell-inline-editing input:focus-visible{
    border: transparent;
}

.section-border {
    border: 1px solid rgb(189, 191, 191);
    border-radius: 0.25rem;
}